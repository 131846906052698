import { template as template_52e7a9282d6342e1b94d2e08eae370a6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_52e7a9282d6342e1b94d2e08eae370a6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
