import { template as template_94e7942db7bd4568baef01a37b302c36 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKField from "discourse/form-kit/components/fk/field";
const FKInputGroup = template_94e7942db7bd4568baef01a37b302c36(`
  <div class="form-kit__input-group">
    {{yield
      (hash
        Field=(component
          FKField
          errors=@errors
          addError=@addError
          data=@data
          set=@set
          remove=@remove
          registerField=@registerField
          unregisterField=@unregisterField
          triggerRevalidationFor=@triggerRevalidationFor
          showMeta=false
        )
      )
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKInputGroup;
