import { template as template_1b233c31c74d4976a863af91294f7466 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1b233c31c74d4976a863af91294f7466(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
