import { template as template_aaf5a82775844609af7e3d7237547d56 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_aaf5a82775844609af7e3d7237547d56(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
